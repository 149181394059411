import { left } from '@popperjs/core';
import { AmountFormatCellComponent } from '@shared/components/amount-format-cell/amount-format-cell.component';
import { MOMENT_DATE_FORMAT } from '@shared/pipes/date-format/common';
import { ColDef, ValueFormatterParams } from 'ag-grid-community/dist/lib/main';
import * as moment from 'moment';
import { amountFormatter } from './instrument-settlement-column-def-edit';
import { SettlementHistoryActionComponent } from './settlement-history-action/settlement-history-action.component';
export interface InstrumentSettlementColumnDef extends ColDef {
  headerName: string;
  children: any[];
}

export const dateFormat = 'DD-MM-YYYY';

export const INSTRUMENT_SETTLEMENT_COLUMNS: InstrumentSettlementColumnDef[] = [
  {
    headerName: 'checkboxSelection',
    children: [
      {
        field: 'checkboxSelection',
        width: 20,
        pinned: left,
        headerCheckboxSelection: true,
        checkboxSelection: true
      }
    ]
  },
  {
    headerName: 'subscriber',
    children: [
      {
        field: 'subscriberName',
        sortable: true,
        editable: false,
        width: 100,
        resizable: true,
        pinned: left
      },
      {
        field: 'subscriberClearing',
        width: 140,
        valueFormatter: (q: ValueFormatterParams) => q.data.subscriberClearing?.valueDescription,
        valueGetter: (q: ValueFormatterParams) => q.data.subscriberClearing?.valueDescription,
        sortable: true,
        editable: false,
        resizable: true,
        pinned: left
      },
      {
        field: 'subscriberAccountNumber',
        width: 130,
        sortable: true,
        editable: false,
        resizable: true,
        pinned: left
      }
    ]
  },
  {
    headerName: 'settlement',
    children: [
      {
        field: 'paymentType',
        width: 100,
        valueFormatter: (q: ValueFormatterParams) => q.data.paymentType?.valueDescription,
        valueGetter: (q: ValueFormatterParams) => q.data.paymentType?.valueDescription,
        sortable: true,
        editable: false,
        resizable: true
      },
      {
        field: 'amount',
        width: 140,
        cellRendererFramework: AmountFormatCellComponent,
        valueFormatter: (params: { value: string }) => amountFormatter(params) ?? '',
        sortable: true,
        editable: false,
        resizable: true,
        type: 'rightAligned',
        cellStyle: { display: 'flex', 'justify-content': 'flex-end' }
      },
      {
        field: 'numberOfshares',
        width: 140,
        cellRendererFramework: AmountFormatCellComponent,
        sortable: true,
        editable: false,
        resizable: true,
        cellStyle: { display: 'flex', 'justify-content': 'flex-end' },
        type: 'rightAligned'
      },
      {
        field: 'issuePrice',
        width: 140,
        sortable: true,
        editable: false,
        resizable: true,
        type: 'rightAligned',
        cellStyle: { display: 'flex', 'justify-content': 'flex-end' }
      },
      {
        field: 'netAmount',
        cellRendererFramework: AmountFormatCellComponent,
        valueFormatter: (params: { value: string }) => amountFormatter(params) ?? '',
        width: 140,
        sortable: true,
        editable: false,
        resizable: true,
        type: 'rightAligned',
        cellStyle: { display: 'flex', 'justify-content': 'flex-end' }
      },
      {
        field: 'tradeDateAsString',
        width: 140,
        valueFormatter: (q: ValueFormatterParams): string => {
          if (!q.data.tradeDateAsString) {
            return '';
          }
          const momentDate = moment(q.data.tradeDateAsString);
          return momentDate.format(MOMENT_DATE_FORMAT);
        },
        editable: false,
        resizable: true
      },
      {
        field: 'settlementDateAsString',
        width: 140,
        valueFormatter: (q: ValueFormatterParams): string => {
          if (!q.data.settlementDateAsString) {
            return '';
          }
          const momentDate = moment(q.data.settlementDateAsString);
          return momentDate.format(MOMENT_DATE_FORMAT);
        },
        sortable: true,
        editable: false,
        resizable: true
      }
    ]
  },
  {
    headerName: 'SGLUX',
    children: [
      {
        field: 'issuingAgentClearing',
        valueFormatter: (q: ValueFormatterParams) => q.data.issuingAgentClearing?.valueDescription,
        valueGetter: (q: ValueFormatterParams) => q.data.issuingAgentClearing?.valueDescription,
        width: 150,
        sortable: true,
        editable: false,
        resizable: true
      },
      {
        field: 'clearingAccountNumber',
        valueFormatter: (q: ValueFormatterParams) => q.data.clearingAccountNumber?.valueDescription,
        valueGetter: (q: ValueFormatterParams) => q.data.clearingAccountNumber?.valueDescription,
        width: 120,
        sortable: true,
        editable: false,
        resizable: true
      },
      {
        field: 'confirmationSgssGenerationDateAsString',
        valueFormatter: (q: ValueFormatterParams) => {
          if (!q.data.confirmationSgssGenerationDateAsString) {
            return '';
          }
          const momentDate = moment(q.data.confirmationSgssGenerationDateAsString);
          return momentDate.format(MOMENT_DATE_FORMAT);
        },
        width: 140,
        sortable: true,
        editable: false,
        resizable: true
      },
      {
        field: 'comments',
        width: 120,
        sortable: true,
        editable: false,
        resizable: true
      },
      {
        field: 'issuingSettlementStatus',
        valueFormatter: function (data: any) {
          return data?.value?.valueDescription ? data.value.valueDescription : data?.value;
        },
        width: 150,
        sortable: true,
        editable: false,
        resizable: true
      }
    ]
  },
  {
    headerName: '',
    children: [
      {
        field: 'action',
        cellRendererFramework: SettlementHistoryActionComponent,
        width: 100
      }
    ]
  }
];
