/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { InstrumentSettlementColumnDefService } from '@instruments/components/instrument-list/_aggrid/instrument-settlement-column-def-service';
import { InstrumentModel, TrancheModel } from '@instruments/models';
import { SettlementModel } from '@instruments/models/settlement.model';
import { InstrumentFormService, InstrumentModalsService } from '@instruments/services';
import { InstrumentService } from '@shared/services/instrument.service';
import { PermissionService } from '@shared/services/permission.service';
import { ColDef, ColGroupDef, GridApi, GridOptions, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IpaTypeModelRules as R } from '@instruments/rules';
import { INSTRUMENT_CONSTANTS } from '@instruments/configs/instrument-constants';
import { UpdateStatusSettlementRequestModel } from '@instruments/models/update-status-settlement-request.model';
import { SETLLEMENT_PRIMARY_ACTIONS_ARRAY, SETTLEMENT_ACTIONS, SETTLEMENT_PRIMARY_ACTIONS } from '@shared/models/settlement-action.model';
import { notEmpty } from '@utils/utility-functions';
import { SettlementActionsService } from './settlement-actions.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-instrument-settlement-consult',
  templateUrl: './consult.component.html',
  providers: [InstrumentSettlementColumnDefService, SettlementActionsService],
  styleUrls: ['./consult.component.scss']
})
export class InstrumentSettlementConsultComponent implements OnInit, OnDestroy {
  @Input() settlemnts?: SettlementModel[] = [];
  @Input() canModify?: boolean;
  @Input() canSave?: boolean;
  @Output() canModifyChange = new EventEmitter<boolean>();
  @Output() canSaveChange = new EventEmitter<boolean>();
  public gridOptions!: GridOptions;
  public columnDefs: ColGroupDef[] = [];
  public defaultColDef: unknown;
  public gridApi!: GridApi;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public instrumentId?: number;
  public selectedTranche: TrancheModel | undefined;
  private readonly subscriptions: Subscription[] = [];
  public shutdown$ = new Subject<void>();
  private canEditInstrument = false;
  public selectedRowsCountIds: string[] = [];
  public isInternalIssuer = false;
  public actions: string[] = [...SETLLEMENT_PRIMARY_ACTIONS_ARRAY];
  allowedActions: SETTLEMENT_ACTIONS[] = [];
  public get origin(): InstrumentOrigin {
    return this.isInternalIssuer ? 'STA' : 'SPI';
  }
  selectedSettlements: SettlementModel[] = [];

  constructor(
    private readonly instrumentFormService: InstrumentFormService,
    private readonly instrumentSettlementColumnDefService: InstrumentSettlementColumnDefService,
    private readonly instrumentService: InstrumentService,
    private readonly permissionService: PermissionService,
    private readonly instrumentModalsService: InstrumentModalsService,
    public readonly settlementActionsService: SettlementActionsService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    const instrument = this.instrumentFormService.value();
    this.isInternalIssuer = instrument?.isInternalIssuer || false;
    this.instrumentId = instrument?.id || 1;
    this.settlementActionsService.allowedActions$.subscribe(allowedActions => {
      this.allowedActions = allowedActions.filter((q: SETTLEMENT_ACTIONS) => this.actions.includes(q));
    });
    this.subscriptions.push(
      this.instrumentFormService.currentTranche$.subscribe(tranche => {
        this.selectedTranche = tranche || null;
        this.load();
      }),
      this.permissionService.canEditInstrument$.subscribe({
        next: (canEditInstrument: boolean) => {
          this.canEditInstrument = canEditInstrument;
        }
      })
    );

    this.columnDefs = this.instrumentSettlementColumnDefService.getWithCustomAmounts(instrument);

    this.gridOptions = {
      pagination: false,
      suppressPaginationPanel: false,
      paginationPageSize: 10,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };
    this.defaultColDef = {
      sortable: false,
      filter: false,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true }
    };
  }

  public get isConsult(): boolean {
    return this.instrumentFormService.formMode === 'consult';
  }

  public get isEditMode(): boolean {
    return this.instrumentFormService.formMode === 'edit';
  }

  load(): void {
    if (this.selectedTranche) {
      this.instrumentService
        .getAllSettlements(this.instrumentId, this.selectedTranche.trancheNumber)
        .pipe(takeUntil(this.shutdown$))
        .subscribe(result => {
          if (result) {
            this.settlemnts = result;
          }
        });
    }
  }

  public get canModifyBtn(): boolean {
    return this.isConsult && this.canEditInstrument && !this.isCurrentTrancheClosedOrCancled && !this.isInstrumentCancelled && !this.isInstrumentClosed;
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.refreshHeader();

    if (!this.canModifySettlements) {
      this.gridOptions.columnApi?.setColumnsVisible(['action'], false);
    }
    if (R.isTradingMethodNominal(this.instrumentFormService.rawValue('tradingMethodIdentifier'))) {
      this.gridOptions.columnApi?.setColumnsVisible(['numberOfshares'], false);
    } else {
      this.gridOptions.columnApi?.setColumnsVisible(['amount'], false);
    }
  }

  onModify(): void {
    if (this.canModify) {
      this.canModify = false;
      this.canSave = true;
      this.canModifyChange.emit(false);
      this.canSaveChange.emit(true);
    }
  }
  public export(): void {
    const groups = this.columnDefs.filter(q => !q.children.some(c => (c as ColDef).field === 'action' || (c as ColDef).field === 'checkboxSelection')).map(g => g.children);
    const children = (
      groups.reduce((a, b) => {
        return a.concat(b);
      }, []) as ColDef[]
    )
      .map(q => q.field)
      .filter(notEmpty);
    if (R.isTradingMethodNominal(this.instrumentFormService.rawValue('tradingMethodIdentifier'))) {
      const index = children.indexOf('numberOfshares', 0);
      if (index > -1) {
        children.splice(index, 1);
      }
    } else {
      const index = children.indexOf('amount', 0);
      if (index > -1) {
        children.splice(index, 1);
      }
    }
    this.gridApi.exportDataAsExcel({
      columnKeys: children,
      processCellCallback: params => {
        const colDef = params.column.getColDef();
        // try to reuse valueFormatter from the colDef
        if (colDef.valueFormatter && typeof colDef.valueFormatter !== 'string' && params.node) {
          const valueFormatterParams: ValueFormatterParams = {
            ...params,
            data: params?.node?.data,
            node: params.node,
            colDef: params.column.getColDef()
          };
          const formatter = colDef.valueFormatter as (params: ValueFormatterParams) => string;
          return formatter(valueFormatterParams);
        }
        return params.value;
      }
    });
  }
  public get isCurrentTrancheClosedOrCancled(): boolean {
    return R.isClosed(this.selectedTranche?.status) || R.isCancelled(this.selectedTranche?.status);
  }
  public get canModifySettlements(): boolean {
    return this.canEditInstrument && !this.isCurrentTrancheClosedOrCancled && !this.isInstrumentCancelled && !this.isInstrumentClosed;
  }
  public get isInstrumentCancelled(): boolean {
    return this.instrument.status?.valueDescription === INSTRUMENT_CONSTANTS.Types.statuses.CANCELLED;
  }
  public get isInstrumentClosed(): boolean {
    return this.instrument.status?.valueDescription === INSTRUMENT_CONSTANTS.Types.statuses.CLOSED;
  }
  public get instrument(): Nullable<InstrumentModel> {
    return this.instrumentFormService.rawValue();
  }

  private updateSettlementsStatus(action: SETTLEMENT_ACTIONS) {
    const params: UpdateStatusSettlementRequestModel = {
      settlementNumbers: this.selectedRowsCountIds,
      instrumentId: this.instrumentId?.toString(),
      isin: this.instrumentFormService.rawValue().isinReference,
      trancheNumber: this.selectedTranche?.trancheNumber.toString()
    };
    this.instrumentService
      .updateSettlementsStatus(action, params)
      .pipe(takeUntil(this.shutdown$))
      .subscribe(_ => {
        if (this.selectedTranche) {
          const instConsultRouteFragment = '/instrument/consult';
          const instRoute = `${instConsultRouteFragment}/${this.instrumentId?.toString()}`;
          const currentRoute = `${instRoute}/settlements/${this.selectedTranche.trancheNumber}`;
          this.router.navigateByUrl(instRoute, { skipLocationChange: true }).then(() => this.router.navigate([currentRoute], { state: { tabSelectionRequest: 'settlements' } }));
        }
      });
  }

  onApplyAction(action: SETTLEMENT_ACTIONS): void {
    const title = 'modals.titles.' + action;
    const body = 'modals.contents.' + action;
    const modal = this.instrumentModalsService.openCancelModal(title, body);
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      this.updateSettlementsStatus(action);
    });
  }

  public get isDisabledActions(): boolean {
    return this.selectedRowsCountIds.length <= 0;
  }

  onSelectionChanged(): void {
    const selectedRows = this.gridApi.getSelectedNodes();
    this.selectedSettlements = selectedRows.map(q => q.data);
    this.settlementActionsService.onAllowedActions(this.selectedSettlements, this.origin);
    const selectedSortedRows: string[] = [];
    this.gridApi.forEachNode(node => {
      const row = selectedRows.find(row => row.id === node.id)?.data;
      if (row) {
        selectedSortedRows.push(row.id);
      }
    });
    this.selectedRowsCountIds = selectedSortedRows;
  }
  canPerformAction(action: SETTLEMENT_PRIMARY_ACTIONS): boolean {
    return this.allowedActions.includes(action);
  }
  resetSelection(): void {
    this.selectedRowsCountIds = [];
    this.gridApi.deselectAll();
  }
  label(action: string): string {
    return 'instruments.instrument.forms.settlement.actions.' + action;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
