/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { formatNumber } from '@angular/common';
import { left } from '@popperjs/core';
import { ColDef } from 'ag-grid-community/dist/lib/main';
import * as moment from 'moment';
import { NumericEditorComponent } from './custom-cell-editor/numeric-cell.component';
import { SettlementEditActionComponent } from './settlement-edit-action/settlement-edit-action.component';
export interface InstrumentSettlementColumnDef extends ColDef {
  headerName: string;
  children: any[];
}

export const formatDate = 'DD-MM-YYYY';

export const INSTRUMENT_SETTLEMENT_COLUMNS_EDITABLE: InstrumentSettlementColumnDef[] = [
  {
    headerName: 'checkboxSelection',
    children: [
      {
        field: 'checkboxSelection',
        width: 20,
        pinned: left,
        headerCheckboxSelection: true,
        checkboxSelection: true
      }
    ]
  },
  {
    headerName: 'subscriber',
    children: [
      {
        field: 'subscriberName',
        pinned: left,
        sortable: true,
        editable: true,
        width: 100,
        resizable: true,
        singleClickEdit: true
      },
      {
        field: 'subscriberClearing',
        singleClickEdit: true,
        pinned: left,
        cellEditor: 'agSelectCellEditor',
        cellRenderer: function (data: any) {
          return data?.value?.valueDescription ? data.value.valueDescription : data?.value;
        },
        width: 140,
        sortable: true,
        editable: true,
        resizable: true
      },
      {
        field: 'subscriberAccountNumber',
        cellEditorFramework: NumericEditorComponent,
        width: 130,
        sortable: true,
        editable: true,
        resizable: true,
        pinned: left,
        singleClickEdit: true
      }
    ]
  },
  {
    headerName: 'settlement',
    children: [
      {
        field: 'paymentType',
        singleClickEdit: true,
        cellEditor: 'agSelectCellEditor',
        cellRenderer: function (data: any) {
          return data?.value?.valueDescription ? data.value.valueDescription : data?.value;
        },
        width: 100,
        sortable: true,
        editable: true,
        resizable: true
      },
      {
        field: 'amount',
        valueFormatter: amountFormatter,
        width: 140,
        cellEditorFramework: NumericEditorComponent,
        sortable: true,
        editable: true,
        resizable: true,
        singleClickEdit: true
      },
      {
        field: 'numberOfshares',
        valueFormatter: amountFormatter,
        width: 140,
        cellEditorFramework: NumericEditorComponent,
        sortable: true,
        editable: true,
        resizable: true,
        singleClickEdit: true
      },
      {
        field: 'issuePrice',
        width: 140,
        valueFormatter: amountFormatter,
        cellEditorFramework: NumericEditorComponent,
        sortable: true,
        editable: true,
        resizable: true,
        singleClickEdit: true
      },
      {
        field: 'netAmount',
        width: 140,
        valueFormatter: amountFormatter,
        cellEditorFramework: NumericEditorComponent,
        sortable: true,
        editable: true,
        resizable: true,
        singleClickEdit: true
      },
      {
        field: 'tradeDateAsString',
        cellEditor: 'agDateInput',
        valueFormatter: dateFormatter,
        width: 140,
        sortable: true,
        editable: true,
        resizable: true,
        singleClickEdit: true
      },
      {
        field: 'settlementDateAsString',
        width: 140,
        cellEditor: 'agDateInput',
        valueFormatter: dateFormatter,
        sortable: true,
        editable: true,
        resizable: true,
        singleClickEdit: true
      }
    ]
  },
  {
    headerName: 'SGLUX',
    children: [
      {
        field: 'issuingAgentClearing',
        singleClickEdit: true,
        cellEditor: 'agSelectCellEditor',
        cellRenderer: function (data: any) {
          return data?.value?.valueDescription ? data.value.valueDescription : data?.value;
        },
        width: 150,
        sortable: true,
        editable: true,
        resizable: true
      },
      {
        field: 'clearingAccountNumber',
        singleClickEdit: true,
        cellEditor: 'agSelectCellEditor',
        cellRenderer: function (data: any) {
          return data?.value?.valueDescription ? data.value.valueDescription : data?.value;
        },
        width: 120,
        sortable: true,
        editable: true,
        resizable: true
      },
      {
        field: 'confirmationSgssGenerationDateAsString',
        cellEditor: 'agDateInput',
        valueFormatter: dateFormatter,
        width: 140,
        sortable: true,
        editable: true,
        resizable: true,
        singleClickEdit: true
      },
      {
        field: 'comments',
        width: 120,
        sortable: true,
        editable: true,
        resizable: true,
        singleClickEdit: true
      },
      {
        field: 'issuingSettlementStatus',
        valueFormatter: function (data: any) {
          return data?.value?.valueDescription ? data.value.valueDescription : data?.value;
        },
        width: 150,
        sortable: true,
        editable: false,
        resizable: true
      },
      {
        headerName: '',
        children: [
          {
            field: 'action',
            cellRendererFramework: SettlementEditActionComponent,
            width: 100
          }
        ]
      }
    ]
  }
];
export function amountFormatter(params: { value: string }): string | null {
  if (params.value) {
    const parsedValue = +(params.value || '').split(',').join('');
    return formatNumber(parsedValue, 'en', '1.4-4');
  }
  return null;
}
export function dateFormatter(params: { value: Date | undefined | null }) {
  return params.value ? moment(params.value).format('DD-MM-YYYY') : '';
}
