import { Component, EventEmitter, Output, Input } from '@angular/core';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { TYPED_DATA_TYPE } from '@shared/models';
import { PaymentCriteriaSearchModel } from 'src/app/payment/models';
import { PaymentCriteriaSearchFormService } from 'src/app/payment/services';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-payment-list-filter',
  templateUrl: './payment-list-filter.component.html'
})
export class PaymentListFilterComponent extends BaseFormComponent<PaymentCriteriaSearchModel, PaymentCriteriaSearchFormService> {
  @Output() exportCSV = new EventEmitter<boolean>();
  @Input() disableExportCsv = false;
  constructor(paymentCriteriaSearchFormService: PaymentCriteriaSearchFormService) {
    super(paymentCriteriaSearchFormService, 'payment.list.filters');
  }
  public EVENT_TYPE: TYPED_DATA_TYPE = 'EVENT_TYPE';
  public eventTypesDropDown = ['INTR', 'REDM', 'MCAL', 'PRED', 'PCAL', 'DVCA'];
  public PAYMENTS_CONFIRMATION_STATUS: TYPED_DATA_TYPE = 'PAYMENTS_CONFIRMATION_STATUS';
  public paymentstatusDropDown = ['DUPLICATE', 'REGULARIZED', 'AWAIT NEW PAYMENT', 'CANCELLED', 'PAYMENT IN REPAIR', 'RECONCILED'];

  get valueBeginDate(): number | null {
    const formDate = this.formService?.rawValue()?.valueFromDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }
  get valueEndDate(): number | null {
    const formDate = this.formService?.rawValue()?.valueToDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }

  private getDateTime(formDate: string) {
    const date = formDate.split('-');
    const newDate = new Date(+date[2], +date[1] - 1, +date[0]);
    return newDate.getTime();
  }

  exportCsv() {
    this.exportCSV.emit(true);
  }

  public get canExport(): boolean {
    return Object.keys(this.formService.fields)
      .map((_, index, array) => {
        const item = array[index];
        return this.formService.control(item as keyof PaymentCriteriaSearchModel);
      })
      .some(q => q.value !== null && q.value !== undefined);
  }

  onDatesChanged(e: any, fromDate: any, toDate: any) {
    this.formService.patch(fromDate, e?.detail?.from?.formatted);
    this.formService.control(fromDate).markAsDirty();
    this.formService.patch(toDate, e?.detail?.to?.formatted);
    this.formService.control(toDate).markAsDirty();
  }

  onMinAmountEntered(event: any) {
    if (event) {
      const maxAmount = this.formGroup.get('maxAmount');
      maxAmount?.setValidators([Validators.required]);
    } else {
      const maxAmount = this.formGroup.get('maxAmount');
      maxAmount?.clearValidators();
    }
  }
}
