<div class="d-flex flex-row justify-content-between mb-0 mt-2">
  <app-instrument-settlement-values class="col-md-6"></app-instrument-settlement-values>
  <div>
    <button class="btn btn-flat-secondary" (click)="export()" translate> common-buttons.export</button>
    <button *ngIf="canModifyBtn" class="btn btn-flat-secondary btn-icon-start" (click)="onModify()" (keyup.enter)="onModify()">
      <em class="icon">mode_edit</em>
      <span class="title-case" translate>Modify</span>
    </button>
  </div>
</div>

<div *ngIf="canModifyBtn && settlemnts && !isDisabledActions" class="alert alert-info py-2 px-3 mb-0 mt-2 d-flex flex-between">
  <div class="d-flex" *ngIf="!isDisabledActions">
    <button (click)="resetSelection()" class="btn btn-outline-primary-alt">
      <em class="icon">remove</em>
    </button>
    <span class="mt-2 ms-2">{{ selectedRowsCountIds.length }} selected </span>
  </div>
  <div class="d-flex">
    <button *ngIf="canPerformAction('CANCEL')" class="btn btn-flat-primary-alt ms-2" [disabled]="isDisabledActions" (click)="onApplyAction('CANCEL')" translate>instruments.instrument.forms.settlement.actions.CANCEL</button>
    <button *ngIf="canPerformAction('REJECT')" class="btn btn-discreet-primary-alt ms-2" [disabled]="isDisabledActions" (click)="onApplyAction('REJECT')" translate>
      <em class="icon me-1">close</em>
      instruments.instrument.forms.settlement.actions.REJECT</button
    >
    <button *ngIf="canPerformAction('VALIDATE')" class="btn btn-outline-primary-alt ms-2" [disabled]="isDisabledActions" (click)="onApplyAction('VALIDATE')" translate>
      <em class="icon me-1">done</em>
      instruments.instrument.forms.settlement.actions.VALIDATE</button
    >
    <app-instrument-settlement-actions [isInternalIssuer]="isInternalIssuer" [selectedSettlements]="selectedSettlements" (action)="onApplyAction($event)"> </app-instrument-settlement-actions>
  </div>
</div>

<div class="row mt-2">
  <div *ngIf="settlemnts!.length > 0; else noData" class="col-md-12">
    <ag-grid-angular
      #agGrid
      class="ag-theme-sg-bootstrap-condensed"
      [gridOptions]="gridOptions!"
      style="height: 600px"
      (selectionChanged)="onSelectionChanged()"
      [defaultColDef]="defaultColDef"
      [columnDefs]="columnDefs"
      [rowData]="settlemnts"
      [rowSelection]="rowSelection"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </div>
</div>

<ng-template #noData>
  <div class="col-12 justify-content-center" [class.d-none]="!settlemnts" [class.d-flex]="settlemnts">
    <div class="text-center text-secondary">
      <em class="icon icon-xl d-inline-block">search</em>
      <div class="h4" translate>ag-grid.title</div>
      <p translate>ag-grid.modifySubTitle</p>
    </div>
  </div>
</ng-template>
