const paymentInRepair = 'PAYMENT IN REPAIR';
const awaitNewPayment = 'AWAIT NEW PAYMENT';

export const PAYMENT_CONSTANTS = {
  payment: {
    paymentStatus: ['RECONCILED', 'NOT RECONCILED', paymentInRepair, 'REJECTED', 'PAYMENT_PENDING', 'CANCELLED', awaitNewPayment, 'REGULARIZED', 'DUPLICATE'],
    paymentStatusDropDown: [paymentInRepair, awaitNewPayment],
    awaitNewPayments: ['Amount mismatch', 'Currency mismatch', 'Value date mismatch'],
    regularized: [paymentInRepair, awaitNewPayment]
  }
};
