import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { INSTRUMENT_CONSTANTS, SETTLEMENT_CONSTANTS } from '@instruments/configs/instrument-constants';
import { PagingRequestWithCriteriaModel } from '@instruments/models';
import { PartyModel, ROLES_TYPE, TYPE_FILTER_TYPE, TypedDataModel } from '@shared/models';
import { InstrumentService } from '@shared/services/instrument.service';
import { PagingResultDtoInstrumentDataFetchDto } from '@apis/backend/instruments';

import { IssuingSettlementIndicatorCellClickPayload, IssuingSettlementIndicatorRow } from '../issuing-settlement-indicators-grid/issuing-settlement-indicators-grid.model';
import { IssuingSettlementIndicatorQueries, IssuingSettlementIndicators, QueryParameters } from './issuing-settlement-indicators.model';

/* eslint-disable prettier/prettier */
@Injectable()
export class IssuingSettlementIndicatorsService {
  private readonly baseQuery: PagingRequestWithCriteriaModel = {
    itemsByPage: 50,
    pageNumber: 1
  };

  private readonly staIssuersRef = [INSTRUMENT_CONSTANTS.Issuers.SGE, INSTRUMENT_CONSTANTS.Issuers.SGIS, INSTRUMENT_CONSTANTS.Issuers.SGOE, INSTRUMENT_CONSTANTS.Issuers.SG_PARIS];
  private queries: IssuingSettlementIndicatorQueries | null = null;
  private staIssuers: PartyModel[] = [];
  private spiIssuers: PartyModel[] = [];
  private settlementWorkflowStatusesByDescription: { [key: string]: TypedDataModel } = {};
  private trancheWorkflowStatusesByDescription: { [key: string]: TypedDataModel } = {};
  private instrumentTypesByDescription: { [key: string]: TypedDataModel } = {};
  private readonly QUERIES_COUNT = 16;

  constructor(private readonly instrumentsService: InstrumentService, private readonly router: Router) {}

  getIssuingSettlementIndicatorRows$(partiesData: [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][], typeReferenceData: Record<string, TypedDataModel[]>): Observable<IssuingSettlementIndicatorRow[]> {
    const parties = this.getParties(partiesData, 'INSTRUMENT', 'ISSUER');
    this.staIssuers = parties.filter(x => x.name && this.staIssuersRef.includes(x.name));
    this.spiIssuers = parties.filter(x => x.name && !this.staIssuersRef.includes(x.name));

    const hashifyTypedDataModel = (data: TypedDataModel[]) =>
      data.reduce((acc, cur) => {
        acc[cur.valueDescription] = cur;
        return acc;
      }, {} as { [key: string]: TypedDataModel });

    this.settlementWorkflowStatusesByDescription = hashifyTypedDataModel(typeReferenceData['SETTLEMENT_WORKFLOW_STATUS']);
    this.trancheWorkflowStatusesByDescription = hashifyTypedDataModel(typeReferenceData['TRANCHE_WORKFLOW_STATUS']);
    this.instrumentTypesByDescription = hashifyTypedDataModel(typeReferenceData['INSTRUMENT_TYPE']);
    
    const todayPlusOneDate = this.getNextWorkableDay(1);
    const todayPlusTwoDate = this.getNextWorkableDay(2);
    const todayPlusThreeDate = this.getNextWorkableDay(3);

    this.queries = {
      Today: this.buildQueries(new Date(), undefined, false, 'D'),
      TodayPlusOneDay: this.buildQueries(todayPlusOneDate, todayPlusOneDate, false, 'D+1'),
      TodayPlusTwoDays: this.buildQueries(todayPlusTwoDate, todayPlusTwoDate, false, 'D+2'),
      TodayPlusThreeDays: this.buildQueries(todayPlusThreeDate, todayPlusThreeDate, true, 'D+3')
    };

    const flatQueries = Object.values(this.queries).reduce((acc, cur) => {
      const queries = Object.values(cur).reduce((acc, cur) => {
        acc.push(cur);
        return acc;
      }, [] as PagingRequestWithCriteriaModel[]);
      acc.push(...queries);
      return acc;
    }, [] as PagingRequestWithCriteriaModel[]);

    return forkJoin(flatQueries
      .map(x => this.instrumentsService.getAllInstrumentsPaged(x)))
      .pipe(map(x => this.buildIssuingSettlementIndicatorRows(x)));
  }

  navigateToInstruments(data: IssuingSettlementIndicatorCellClickPayload): void {
    const { period, rowData } = data;
    if (this.queries) {
      const targetQuery = this.queries[period][rowData.status];
      this.router.navigate(['/instrument'], {
        queryParams: {
          query: JSON.stringify({
            ...targetQuery,
            ...targetQuery.criteria
          })
        }
      });
    }
  }

  private getParties(partiesData: [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][] | undefined, filter: TYPE_FILTER_TYPE, role: ROLES_TYPE): PartyModel[] {
    return (partiesData?.find(q => q[0] === filter && q[1] === role) ?? [filter, role, []])[2] ?? [];
  }

  private buildBaseQuery(endDate: Date, startDate: Date | undefined = undefined, issuerPerimeter: string | undefined = undefined) {
    return {
      ...this.baseQuery,
      criteria: {
        ...this.baseQuery.criteria,
        beginIssuedate: startDate ? formatDate(startDate, 'dd-MM-yyyy', 'en') : startDate,
        endIssuedate: formatDate(endDate, 'dd-MM-yyyy', 'en'),
        issuerPerimter: issuerPerimeter as any
      }
    };
  }

  private buildQuery({ endDate, startDate, issuerPerimeter, withoutSettlement, settlementStatus, status, isin, instrumentType, tag }: QueryParameters): PagingRequestWithCriteriaModel {
    const baseQuery = this.buildBaseQuery(endDate, startDate, issuerPerimeter);
    return {
      ...baseQuery,
      criteria: {
        ...baseQuery.criteria,
        withoutSettlement,
        settlementStatus,
        status,
        isin,
        instrumentType
      },
      tag
    };
  }

  private buildQueries(endDate: Date, startDate: Date | undefined = undefined, onlySpi = false, tagPostfix: string | undefined = undefined): any {
    const inRepairSettlementStatus = this.settlementWorkflowStatusesByDescription[SETTLEMENT_CONSTANTS.statuses.IN_REPAIR];
    const paymentToBeValidatedSettlementStatus = this.settlementWorkflowStatusesByDescription[SETTLEMENT_CONSTANTS.statuses.PAYMENT_TO_BE_VALIDATED];
    const deliveryToBeValidatedSettlementStatus = this.settlementWorkflowStatusesByDescription[SETTLEMENT_CONSTANTS.statuses.DELIVERY_TO_BE_VALIDATED];
    const securitiesToBeValidatedSettlementStatus = this.settlementWorkflowStatusesByDescription[SETTLEMENT_CONSTANTS.statuses.SECURITIES_TO_BE_VALIDATED];
    const securitiesValidatedSettlementStatus = this.settlementWorkflowStatusesByDescription[SETTLEMENT_CONSTANTS.statuses.SECURITIES_VALIDATED];
    const paymentValidatedSettlementStatus = this.settlementWorkflowStatusesByDescription[SETTLEMENT_CONSTANTS.statuses.PAYMENT_VALIDATED];
    const liveStatus = this.trancheWorkflowStatusesByDescription[INSTRUMENT_CONSTANTS.Types.statuses.LIVE];
    const initializedStatus = this.trancheWorkflowStatusesByDescription[INSTRUMENT_CONSTANTS.Types.statuses.INITIALIZED];
    const instrumentTypeWarrant = this.instrumentTypesByDescription[INSTRUMENT_CONSTANTS.Types.instrumentTypes.WARRANT];

    return onlySpi
      ? {
        'DELIVERY TBV': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', settlementStatus: [deliveryToBeValidatedSettlementStatus], tag: `DELIVERY_TBV_${tagPostfix}` }),
        'SECURITIES TBV SPI': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', settlementStatus: [securitiesToBeValidatedSettlementStatus], tag: `SECURITIES_TBV_SPI_${tagPostfix}` }),
        'SECURITIES VALIDATED': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', settlementStatus: [securitiesValidatedSettlementStatus], tag: `SECURITIES_VALIDATED_${tagPostfix}` }),
        'PAYMENT TBV SPI': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', settlementStatus: [paymentToBeValidatedSettlementStatus], tag: `PAYMENT_TBV_SPI_${tagPostfix}` }),
        'SETTLEMENT IN REPAIR SPI': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', settlementStatus: [inRepairSettlementStatus], tag: `SETTLEMENT_IN_REPAIR_SPI_${tagPostfix}` }),
        'NO SETTLEMENT SPI': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', withoutSettlement: true, tag: `NO_SETTLEMENT_SPI_${tagPostfix}`, status: initializedStatus }),
        'INSTRUMENT INITIALIZED SPI': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', status: initializedStatus, tag: `INSTRUMENT_INITIALIZED_SPI_${tagPostfix}` }),
        'INSTRUMENT LIVE SPI': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', status: liveStatus, tag: `INSTRUMENT_LIVE_SPI_${tagPostfix}` })
      }: {
        'NO SETTLEMENT STA': this.buildQuery({ endDate, startDate, issuerPerimeter: 'STA', withoutSettlement: true, isin: 'XS', tag: `NO_SETTLEMENT_STA_${tagPostfix}`, status: initializedStatus }),
        'NO SETTLEMENT SPI': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', withoutSettlement: true, tag: `NO_SETTLEMENT_SPI_${tagPostfix}`, status: initializedStatus }),
        'NO SETTLEMENT WARRANT': this.buildQuery({ endDate, startDate, issuerPerimeter: 'STA', withoutSettlement: true, instrumentType: instrumentTypeWarrant, tag: `NO_SETTLEMENT_WARRANT_${tagPostfix}`, status: initializedStatus }),
        'SETTLEMENT IN REPAIR STA': this.buildQuery({ endDate, startDate, issuerPerimeter: 'STA', settlementStatus: [inRepairSettlementStatus], tag: `SETTLEMENT_IN_REPAIR_STA_${tagPostfix}` }),
        'SETTLEMENT IN REPAIR SPI': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', settlementStatus: [inRepairSettlementStatus], tag: `SETTLEMENT_IN_REPAIR_SPI_${tagPostfix}` }),
        'PAYMENT TBV STA': this.buildQuery({ endDate, startDate, issuerPerimeter: 'STA', settlementStatus: [paymentToBeValidatedSettlementStatus], tag: `PAYMENT_TBV_STA_${tagPostfix}` }),
        'PAYMENT TBV SPI': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', settlementStatus: [paymentToBeValidatedSettlementStatus], tag: `PAYMENT_TBV_SPI_${tagPostfix}` }),
        'SECURITIES TBV STA': this.buildQuery({ endDate, startDate, issuerPerimeter: 'STA', settlementStatus: [securitiesToBeValidatedSettlementStatus], tag: `SECURITIES_TBV_STA_${tagPostfix}` }),
        'SECURITIES TBV SPI': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', settlementStatus: [securitiesToBeValidatedSettlementStatus], tag: `SECURITIES_TBV_SPI_${tagPostfix}` }),
        'SETTLEMENT VALIDATED': this.buildQuery({ endDate: endDate, startDate: endDate, issuerPerimeter: 'STA', settlementStatus: [paymentValidatedSettlementStatus], tag: `SETTLEMENT_VALIDATED_${tagPostfix}` }),
        'INSTRUMENT LIVE': this.buildQuery({ endDate: endDate, startDate: endDate, issuerPerimeter: 'STA', status: liveStatus, tag: `INSTRUMENT_LIVE_${tagPostfix}` }),
        'INSTRUMENT INITIALIZED STA': this.buildQuery({ endDate, startDate, issuerPerimeter: 'STA', status: initializedStatus, tag: `INSTRUMENT_INITIALIZED_STA_${tagPostfix}` }),
        'INSTRUMENT INITIALIZED SPI': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', status: initializedStatus, tag: `INSTRUMENT_INITIALIZED_SPI_${tagPostfix}` }),
        'INSTRUMENT LIVE SPI': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', status: liveStatus, tag: `INSTRUMENT_LIVE_SPI_${tagPostfix}` }),
        'SECURITIES VALIDATED': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', settlementStatus: [securitiesValidatedSettlementStatus], tag: `SECURITIES_VALIDATED_${tagPostfix}` }),
        'DELIVERY TBV': this.buildQuery({ endDate, startDate, issuerPerimeter: 'SPI', settlementStatus: [deliveryToBeValidatedSettlementStatus], tag: `DELIVERY_TBV_${tagPostfix}` })
      };
  }

  private buildIssuingSettlementIndicatorRows(results: (PagingResultDtoInstrumentDataFetchDto | null)[]): IssuingSettlementIndicatorRow[] {
    const todayResults = results.slice(0, this.QUERIES_COUNT);
    const todayPlusOneDayResults = results.slice(this.QUERIES_COUNT, this.QUERIES_COUNT * 2);
    const todayPlusTwoDayResults = results.slice(this.QUERIES_COUNT * 2, this.QUERIES_COUNT * 3);
    const todayPlusThreeDayResults = results.slice(this.QUERIES_COUNT * 3, (this.QUERIES_COUNT * 3) + 8);
    const todayIndicators = this.getIssuingSettlementIndicators(todayResults);
    const todayPlusOneDayIndicators = this.getIssuingSettlementIndicators(todayPlusOneDayResults);
    const todayPlusTwoDayIndicators = this.getIssuingSettlementIndicators(todayPlusTwoDayResults);
    const todayPlusThreeDayIndicators = this.getIssuingSettlementIndicatorsThreeDays(todayPlusThreeDayResults);

    return [
      {
        status: 'NO SETTLEMENT STA',
        displayStatus: 'XS Settlement To Be Done',
        todayCount: todayIndicators.noSettlementStaCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.noSettlementStaCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.noSettlementStaCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.noSettlementStaCount,
        scope: 'STA',
        forAction: true
      },
      {
        status: 'NO SETTLEMENT WARRANT',
        displayStatus: 'Warrants Settlement To Be Done',
        todayCount: todayIndicators.noSettlementWarrantCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.noSettlementWarrantCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.noSettlementWarrantCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.noSettlementWarrantCount,
        scope: 'STA',
        forAction: true
      },
      {
        status: 'SETTLEMENT IN REPAIR STA',
        displayStatus: 'Settlement In Repair',
        todayCount: todayIndicators.inRepairStaCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.inRepairStaCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.inRepairStaCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.inRepairStaCount,
        scope: 'STA',
        forAction: true
      },
      {
        status: 'PAYMENT TBV STA',
        displayStatus: 'Payment To Be Validated',
        todayCount: todayIndicators.paymentTbvStaCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.paymentTbvStaCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.paymentTbvStaCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.paymentTbvStaCount,
        scope: 'STA',
        forAction: true
      },
      {
        status: 'NO SETTLEMENT SPI',
        displayStatus: 'Settlement To Be Done',
        todayCount: todayIndicators.noSettlementSpiCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.noSettlementSpiCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.noSettlementSpiCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.noSettlementSpiCount,
        scope: 'SPI',
        forAction: true
      },
      {
        status: 'DELIVERY TBV',
        displayStatus: 'Delivery To Be Validated',
        todayCount: todayIndicators.deliveryToBeValidatedCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.deliveryToBeValidatedCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.deliveryToBeValidatedCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.deliveryToBeValidatedCount,
        scope: 'SPI',
        forAction: true
      },
      {
        status: 'SECURITIES TBV STA',
        displayStatus: 'Securities To Be Validated',
        todayCount: todayIndicators.secTbvStaCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.secTbvStaCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.secTbvStaCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.secTbvStaCount,
        scope: 'STA',
        forAction: true
      },
      {
        status: 'SECURITIES TBV SPI',
        displayStatus: 'Securities To Be Validated',
        todayCount: todayIndicators.secTbvSpiCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.secTbvSpiCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.secTbvSpiCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.secTbvSpiCount,
        scope: 'SPI',
        forAction: true
      },
      {
        status: 'SECURITIES VALIDATED',
        displayStatus: 'Settlement To Be Checked On ICSD Platform',
        todayCount: todayIndicators.securitiesValidatedCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.securitiesValidatedCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.securitiesValidatedCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.securitiesValidatedCount,
        scope: 'SPI',
        forAction: true
      },
      {
        status: 'PAYMENT TBV SPI',
        displayStatus: 'Payment To Be Validated',
        todayCount: todayIndicators.paymentTbvSpiCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.paymentTbvSpiCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.paymentTbvSpiCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.paymentTbvSpiCount,
        scope: 'SPI',
        forAction: true
      },
      {
        status: 'SETTLEMENT VALIDATED',
        displayStatus: 'Settlement Validated',
        todayCount: todayIndicators.paymentValidatedCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.paymentValidatedCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.paymentValidatedCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.paymentValidatedCount,
        scope: 'STA',
        forAction: false
      },
      {
        status: 'INSTRUMENT LIVE',
        displayStatus: 'Instrument Live',
        todayCount: todayIndicators.liveCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.liveCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.liveCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.liveCount,
        scope: 'STA',
        forAction: false
      },
      {
        status: 'INSTRUMENT INITIALIZED STA',
        displayStatus: 'Instrument Initialized',
        todayCount: todayIndicators.initializedStaCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.initializedStaCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.initializedStaCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.initializedStaCount,
        scope: 'STA',
        forAction: false
      },
      {
        status: 'SETTLEMENT IN REPAIR SPI',
        displayStatus: 'Settlement In Repair',
        todayCount: todayIndicators.inRepairSpiCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.inRepairSpiCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.inRepairSpiCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.inRepairSpiCount,
        scope: 'SPI',
        forAction: true
      },
      {
        status: 'INSTRUMENT INITIALIZED SPI',
        displayStatus: 'Instrument Initialized',
        todayCount: todayIndicators.initializedSpiCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.initializedSpiCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.initializedSpiCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.initializedSpiCount,
        scope: 'SPI NOT LIVE',
        forAction: true
      },
      {
        status: 'INSTRUMENT LIVE SPI',
        displayStatus: 'Instrument Live',
        todayCount: todayIndicators.liveSpiCount,
        todayPlusOneDayCount: todayPlusOneDayIndicators.liveSpiCount,
        todayPlusTwoDaysCount: todayPlusTwoDayIndicators.liveSpiCount,
        todayPlusThreeDaysCount: todayPlusThreeDayIndicators.liveSpiCount,
        scope: 'SPI NOT LIVE',
        forAction: true
      }
    ];
  }

  private getIssuingSettlementIndicators(results: (PagingResultDtoInstrumentDataFetchDto | null)[]): IssuingSettlementIndicators {
    const [
      noSettlementStaResult,
      noSettlementSpiResult,
      noSettlementWarrantResult,
      inRepairStaResult,
      inRepairSpiResult,
      paymentTbvStaResult,
      paymentTbvSpiResult,
      secTbvStaResult,
      secTbvSpiResult,
      paymentValidatedResult,
      liveResult,
      initializedStaResult,
      initializedSpiResult,
      liveSpiResult,
      securitiesValidatedResult,
      deliveryToBeValidatedResult] = results;

    return {
      noSettlementStaCount: noSettlementStaResult?.totalItems ?? 0,
      noSettlementSpiCount: noSettlementSpiResult?.totalItems ?? 0,
      noSettlementWarrantCount: noSettlementWarrantResult?.totalItems ?? 0,
      inRepairStaCount: inRepairStaResult?.totalItems ?? 0,
      inRepairSpiCount: inRepairSpiResult?.totalItems ?? 0,
      paymentTbvStaCount: paymentTbvStaResult?.totalItems ?? 0,
      paymentTbvSpiCount: paymentTbvSpiResult?.totalItems ?? 0,
      secTbvStaCount: secTbvStaResult?.totalItems ?? 0,
      secTbvSpiCount: secTbvSpiResult?.totalItems ?? 0,
      paymentValidatedCount: paymentValidatedResult?.totalItems ?? 0,
      liveCount: liveResult?.totalItems ?? 0,
      liveSpiCount: liveSpiResult?.totalItems ?? 0,
      initializedStaCount: initializedStaResult?.totalItems ?? 0,
      initializedSpiCount: initializedSpiResult?.totalItems ?? 0,
      deliveryToBeValidatedCount: deliveryToBeValidatedResult?.totalItems ?? 0,
      securitiesValidatedCount: securitiesValidatedResult?.totalItems ?? 0
    };
  }

  private getIssuingSettlementIndicatorsThreeDays(results: (PagingResultDtoInstrumentDataFetchDto | null)[]): IssuingSettlementIndicators {
    const [
      deliveryToBeValidatedResult,
      secTbvSpiResult,
      securitiesValidatedResult,
      paymentTbvSpiResult,
      settlementInRepairSpiResult,
      noSettlementSpiResult,
      initializedSpiResult,
      liveSpiResult
    ] = results;

    return {
      noSettlementStaCount: 0,
      noSettlementSpiCount: noSettlementSpiResult?.totalItems ?? 0,
      noSettlementWarrantCount: 0,
      inRepairStaCount: 0,
      inRepairSpiCount: settlementInRepairSpiResult?.totalItems ?? 0,
      paymentTbvStaCount: 0,
      paymentTbvSpiCount: paymentTbvSpiResult?.totalItems ?? 0,
      secTbvStaCount: 0,
      secTbvSpiCount: secTbvSpiResult?.totalItems ?? 0,
      paymentValidatedCount: 0,
      liveCount: 0,
      liveSpiCount: liveSpiResult?.totalItems ?? 0,
      initializedStaCount: 0,
      initializedSpiCount: initializedSpiResult?.totalItems ?? 0,
      deliveryToBeValidatedCount: deliveryToBeValidatedResult?.totalItems ?? 0,
      securitiesValidatedCount: securitiesValidatedResult?.totalItems ?? 0
    };
  }

  private isWorkableDay(moment: Moment): boolean {
    return moment.day() !== 0 &&
      moment.day() !== 6 &&
      !(moment.date() === 25 && moment.month() === 11) &&
      !(moment.date() === 1 && moment.month() === 0);
  }

  private getNextWorkableDay(targetNbDays: number): Date {
    let incWorkableDays = 0;
    let currentMoment = moment(new Date());
    
    while (incWorkableDays < targetNbDays) {
      currentMoment = currentMoment.add(1, 'days');
      if (this.isWorkableDay(currentMoment)) {
        incWorkableDays++;
      }
    }

    return currentMoment.toDate();
  }
}
